a.ant-typography,
.ant-typography a {
  @apply text-colorTextBase;
  font-family: inherit;
  text-decoration: underline;
}

.text-header-label {
  @apply line-clamp-1 text-2xl font-bold 2xl:text-[26px] !important;
}
