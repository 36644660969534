@media print {
  @page {
    size: A4;
  }

  .no-print {
    display: none !important;
  }

  .print-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: auto;
    transform: scale(0.7) !important;
    transform-origin: top left;
    overflow: visible;
  }

  .print-content {
    width: 100%;
    max-width: 1000px;
    font-size: 11px;
    background-color: white !important;
    color: black !important;
  }

  .print-content * {
    page-break-inside: avoid;
  }
}
